var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "mx-auto",
      attrs: { "max-width": "1000", color: "transparent" }
    },
    [
      _c(
        "v-row",
        _vm._l(_vm.cards, function(card) {
          return _c(
            "v-col",
            { key: card.to, attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                [
                  _c("v-img", { attrs: { src: card.imgSrc, height: "120" } }),
                  _c("v-card-title", [_vm._v(_vm._s(card.title))]),
                  _c("v-card-text", [_vm._v(_vm._s(card.text))]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "indigo lighten-1",
                            text: "",
                            to: card.to
                          }
                        },
                        [_vm._v(_vm._s(card.actionLabel))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }