var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": _vm.maxWidth, persistent: _vm.persistent },
      model: {
        value: _vm.shown,
        callback: function($$v) {
          _vm.shown = $$v
        },
        expression: "shown"
      }
    },
    [
      _c(
        "v-card",
        [
          _vm.title
            ? _c("v-card-title", {
                staticClass: "headline",
                domProps: { innerHTML: _vm._s(_vm.title) }
              })
            : _vm.$slots.title
            ? _c(
                "v-card-title",
                { staticClass: "headline" },
                [_vm._t("title")],
                2
              )
            : _vm._e(),
          _vm.$slots.body
            ? _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          _vm.allowEnter
                            ? _vm.submit(_vm.actions[0].onclick)
                            : null
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [_vm._t("body")],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$slots["body-raw"]
            ? _c("div", { staticClass: "px-4 py-2" }, [_vm._t("body-raw")], 2)
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._l(_vm.actions.slice().reverse(), function(action, i) {
                return _c(
                  "v-btn",
                  {
                    key: i,
                    attrs: {
                      color: action.color,
                      dark: action.dark,
                      text: action.text,
                      disabled: action.disableByRule && !_vm.valid
                    },
                    on: {
                      click: function($event) {
                        return _vm.submit(action.onclick)
                      }
                    }
                  },
                  [_vm._v(_vm._s(action.label))]
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }