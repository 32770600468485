var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "my-6", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("v-img", {
                attrs: {
                  contain: "",
                  "max-height": 200,
                  src:
                    "https://pbs.twimg.com/profile_images/1067838660035280897/XJfH-OpK_400x400.jpg"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c("div", { staticClass: "text-h3" }, [
                _vm._v("Amazon Mechanical Turk")
              ]),
              _c(
                "v-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        outlined: "",
                        color: "indigo",
                        href: _vm.workerSiteUrl,
                        target: "_blank"
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-account-group")]),
                      _vm._v("Workers ")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        outlined: "",
                        color: "indigo",
                        href: _vm.requesterSiteUrl,
                        target: "_blank"
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-account-circle")]),
                      _vm._v("Requesters ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("credentials-card", {
        attrs: { duct: _vm.duct, crd: _vm.credentials }
      }),
      _c("page-cards")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }