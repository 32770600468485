var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _vm.crd
                ? _c(
                    "v-card",
                    [
                      _c("v-card-text", [
                        _vm._v(
                          " Access Key ID: " + _vm._s(_vm.crd.AccessKeyId)
                        ),
                        _c("br"),
                        _vm._v(
                          " Secret Access Key: " +
                            _vm._s(_vm.crd.SecretAccessKey)
                        ),
                        _c("br"),
                        _vm._v(
                          " Balance: $" +
                            _vm._s(_vm.crd.AccountBalance.AvailableBalance) +
                            " "
                        ),
                        "OnHoldBalance" in _vm.crd.AccountBalance
                          ? _c("span", [
                              _vm._v(
                                " (on hold: " +
                                  _vm._s(_vm.crd.AccountBalance.OnHoldBalance) +
                                  ") "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "pt-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-3",
                              attrs: { outlined: "", color: "grey" },
                              on: {
                                click: function($event) {
                                  return _vm.clearCredentials()
                                }
                              }
                            },
                            [_vm._v(" Clear credentials ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _vm._v(" MTurk account is not set. "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "indigo" },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.dialog.show()
                                }
                              }
                            },
                            [_vm._v(" Set credentials ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c("tutti-dialog", {
        ref: "dialog",
        attrs: {
          title: "Set MTurk Credentials",
          maxWidth: "600",
          actions: [
            {
              label: "Set account",
              color: "indigo darken-1",
              dark: true,
              onclick: _vm.setCredentials
            },
            {
              label: "Cancel",
              color: "grey darken-1",
              text: true
            }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("v-text-field", {
                  attrs: { autofocus: "", filled: "", label: "Access Key Id" },
                  model: {
                    value: _vm.newCredentials.AccessKeyId,
                    callback: function($$v) {
                      _vm.$set(_vm.newCredentials, "AccessKeyId", $$v)
                    },
                    expression: "newCredentials.AccessKeyId"
                  }
                }),
                _c("v-text-field", {
                  attrs: { filled: "", label: "Secret Access Key" },
                  model: {
                    value: _vm.newCredentials.SecretAccessKey,
                    callback: function($$v) {
                      _vm.$set(_vm.newCredentials, "SecretAccessKey", $$v)
                    },
                    expression: "newCredentials.SecretAccessKey"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }